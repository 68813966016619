import AdminSettings from "@/components/Admins/Settings/System/settings";
class Company {
  getData() {
    return AdminSettings.getByName("companyData");
  }
  update(companyData) {
    return AdminSettings.update(companyData.id, companyData);
  }
}
export default new Company();

<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("companyData.companyData") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button v-show="edit" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button v-show="!edit" class="btn btn-primary" @click="save">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <ImageUploadCropper
          aspect-ratio=""
          :default-image="companyLogo"
          :upload-button-text="$t('companyData.uploadLogo')"
          :crop-button-text="$t('companyData.cropLogo')"
          :remove-button-text="$t('companyData.removeLogo')"
          @cropMode="edit = $event"
          @fileUpdated="files = $event"
          @fileRemoved="fileRemoved()"
        >
        </ImageUploadCropper>
        <div class="separator separator-dashed my-5"></div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.name") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.core_data.name"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.nameShort") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.core_data.short"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.language") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="companyData.value.core_data.language_id"
                class="form-control"
                :items="languageOptions"
                item-text="label"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-earth-europe icon-lg" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Languages from "@/components/General/Languages/languages";
import Company from "@/components/Admins/Account/Company/company";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Media from "@/components/Admins/Media/media";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import { bus } from "@/main";
import Swal from "sweetalert2";

export default {
  name: "AdminsAccountCompanyInformation",
  components: {
    ImageUploadCropper
  },
  data() {
    return {
      files: [],
      edit: false,
      companyData: {
        value: {
          core_data: {
            name: "",
            short: "",
            language_id: "",
            logo: ""
          }
        }
      },
      companyLogo: "",
      languageOptions: [],
      isBusy: false
    };
  },
  mounted() {
    this.getData();
    this.getLanguages();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminAccount"),
        route: ""
      },
      {
        title: this.$t("companyData.companyData"),
        route: "/admin/account/company"
      }
    ]);
  },
  methods: {
    getData() {
      let self = this;
      this.isBusy = true;
      Company.getData()
        .then(response => {
          this.companyData = response.data.data;
          if (this.companyData.value.core_data.logo !== null) {
            Media.get(this.companyData.value.core_data.logo)
              .then(mediaResponse => {
                self.companyLogo = mediaResponse.data.data.link;
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
              });
          }
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getLanguages() {
      Languages.getAll()
        .then(response => {
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    async save() {
      this.isBusy = true;
      if (this.files.length) {
        let mediaData = new FormData();
        mediaData.append("files[]", this.files[0].file);
        mediaData.append("description[]", "YEDI Logo");
        await Media.store(mediaData)
          .then(response => {
            if (response.data.data.success) {
              this.deleteLogo();
              this.companyData.value.core_data.logo =
                response.data.data.uploaded[0].id;
            }
          })
          .catch(error => {
            Swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }

      if (!this.companyLogo && !this.files.length) {
        this.deleteLogo();
        this.companyData.value.core_data.logo = null;
      }

      Company.update(this.companyData)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("companyData.companyUpdated")
          });
          this.isBusy = false;
          this.getData();
          bus.$emit("companyDataUpdated");
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    deleteLogo() {
      if (this.companyData.value.core_data.logo !== null) {
        Media.delete(this.companyData.value.core_data.logo);
      }
    },
    fileRemoved() {
      this.files = [];
      this.companyLogo = false;
    }
  }
};
</script>

<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("companyData.accounting") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary" @click="save">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.accountingVatNumber") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.accounting.vatNumber"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.accountingInvoicesEmail") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.accounting.email"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <h5 class="font-weight-bold">
              {{ $t("companyData.bankDetails") }}:
            </h5>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.bankName") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.accounting.bank_data[0].bank_name"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.accountHolder") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.accounting.bank_data[0].account_holder"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.iban") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.accounting.bank_data[0].iban"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.swiftBic") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.accounting.bank_data[0].swift"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Company from "@/components/Admins/Account/Company/company";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      companyData: {
        value: {
          accounting: {
            vatNumber: "",
            email: "",
            bank_data: [
              {
                account_holder: "",
                bank_name: "",
                iban: "",
                swift: ""
              }
            ]
          }
        }
      },
      isBusy: false
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminAccount"),
        route: ""
      },
      {
        title: this.$t("companyData.companyData"),
        route: "/admin/account/company"
      }
    ]);
  },
  methods: {
    getData() {
      this.isBusy = true;
      Company.getData()
        .then(response => {
          this.companyData = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    save() {
      this.isBusy = true;
      Company.update(this.companyData)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("companyData.companyUpdated")
          });
          this.getData();
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-md-4 col-12 d-flex align-items-center">
                <div class="symbol symbol-60 symbol-lg-70 details-symbol">
                  <div
                    class="symbol-label symbol-label-profile"
                    :style="
                      companyLogo
                        ? 'background-image: url(' +
                          companyLogo +
                          '); background-color: #ffffff;'
                        : ''
                    "
                  ></div>
                </div>
              </div>
              <div class="col-md-8 col-12 row align-items-center">
                <div class="col-12">
                  <div class="font-weight-bolder font-size-h5 text-dark-75">
                    <b-skeleton
                      v-if="!companyData.value.core_data.name"
                      type="text"
                      height="1.5rem"
                    />
                    <span>{{ companyData.value.core_data.name }}</span>
                  </div>
                  <div class="text-muted">
                    <b-skeleton
                      v-if="!companyData.value.contact.website"
                      type="text"
                      width="60%"
                    />
                    <span>{{ companyData.value.contact.website }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: isExactActive }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 company-details">
        <companyInformation v-if="$route.name === 'adminAccountData'" />
        <companyContact v-if="$route.name === 'adminAccountDataContact'" />
        <companyBilling v-if="$route.name === 'adminAccountDataBilling'" />
        <companyAccounting
          v-if="$route.name === 'adminAccountDataAccounting'"
        />
      </div>
    </div>
  </div>
</template>

<style>
.disabledTab {
  display: none;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import companyInformation from "@/components/Admins/Account/Company/Information";
import companyContact from "@/components/Admins/Account/Company/Contact";
import companyBilling from "@/components/Admins/Account/Company/Billing";
import companyAccounting from "@/components/Admins/Account/Company/Accounting";
import Company from "@/components/Admins/Account/Company/company";
import Media from "@/components/Admins/Media/media";
import { bus } from "@/main";

export default {
  name: "AdminsAccountCompanyIndex",
  components: {
    companyInformation,
    companyContact,
    companyBilling,
    companyAccounting
  },
  data() {
    return {
      companyData: {
        value: {
          core_data: {
            name: "",
            logo: ""
          },
          contact: {
            website: ""
          }
        }
      },
      companyLogo: false,
      tabIndex: 0,
      tabNavigation: [
        {
          route: "adminAccountData",
          label: "companyData.companyData",
          icon: "fal fa-user"
        },
        {
          route: "adminAccountDataContact",
          label: "companyData.contactData",
          icon: "fal fa-paper-plane"
        },
        {
          route: "adminAccountDataBilling",
          label: "companyData.billing",
          icon: "fal fa-receipt"
        },
        {
          route: "adminAccountDataAccounting",
          label: "companyData.accounting",
          icon: "fal fa-book-open"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminAccount"),
        route: ""
      },
      {
        title: this.$t("companyData.companyData"),
        route: "/admin/account/company/information"
      }
    ]);
    bus.$on("companyDataUpdated", this.loadCompany);
    this.loadCompany();
  },
  destroyed() {
    bus.$off("companyDataUpdated", this.loadCompany);
  },
  methods: {
    loadCompany() {
      let self = this;
      this.isBusy = true;
      Company.getData()
        .then(response => {
          this.companyLogo = false;
          this.companyData = response.data.data;
          if (this.companyData.value.core_data.logo !== null) {
            Media.get(this.companyData.value.core_data.logo)
              .then(response => {
                self.companyLogo = response.data.data.link;
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
                this.isBusy = false;
              });
          }
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  }
};
</script>

<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("companyData.billing") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary" @click="save">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("companyData.billing") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.billingAddress1") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.billing.addressLine1"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.billingAddress2") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.billing.addressLine2"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.billingAddress3") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.billing.addressLine3"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.billingStreet") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.billing.street"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.billingZipcode") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.billing.zipcode"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.billingCity") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="companyData.value.billing.city"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("companyData.billingCountry") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="companyData.value.billing.country_id"
                class="form-control"
                :items="countries"
                item-text="label_german"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-flag icon-lg" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Countries from "@/components/Admins/Settings/System/Countries/countries";
import Company from "@/components/Admins/Account/Company/company";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { bus } from "@/main";

export default {
  data() {
    return {
      companyData: {
        value: {
          billing: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            street: "",
            zipcode: "",
            city: "",
            country_id: ""
          }
        }
      },
      isBusy: false,
      countries: []
    };
  },
  mounted() {
    this.getData();
    this.getCountries();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminAccount"),
        route: ""
      },
      {
        title: this.$t("companyData.companyData"),
        route: "/admin/account/company"
      }
    ]);
  },
  methods: {
    getData() {
      this.isBusy = true;
      Company.getData()
        .then(response => {
          this.companyData = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getCountries() {
      const params = {
        size: 999
      };
      Countries.getAll(params)
        .then(response => {
          this.countries = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    save() {
      this.isBusy = true;
      Company.update(this.companyData)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("companyData.companyUpdated")
          });
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;

          this.getData();
          bus.$emit("companyDataUpdated");
          this.isBusy = false;
        });
    }
  }
};
</script>
